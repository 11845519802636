@import "./variables.scss";

@import "../../node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,400i,700,700i|Ubuntu:300,300i,400,400i,700,700i&subset=latin-ext');

$font-family-sans-serif: 'Ubuntu', sans-serif;
$font-family-monospace: 'Ubuntu Mono', monospace;

$fa-font-path: "../static-npm/fontawesome";
$enable-print-styles: false;
$sidebar-width: 200px;

@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

.custom-select {
  -webkit-appearance: none; // This is a fix for Chrome
}

body.ivis {
  &.sandbox {
    background-color: white;
    overflow-x: hidden;
  }

  &.inside-iframe {
    overflow: hidden;
  }

  .sandbox-loading-message {
    // The 40 px below corresponds to the height in in UntrustedContentHost.render
    height: 40px;
  }

  .dropdown-item {
    border-bottom: none 0px;
  }

  .card {
    .card-header:last-child {
      border-bottom: 0px none;
    }
  }

  .ivis-breadcrumb-and-tertiary-navbar {
    display: flex;
    border-radius: 0;
    @include border-radius($breadcrumb-border-radius);
    //@include borders($breadcrumb-borders);
    background-color: $breadcrumb-bg;
    padding: 0 1rem;

    .breadcrumb {
      border: 0px none;
      margin-bottom: 0px;
      padding-top: 11px;
      padding-bottom: 12px;
    }
  }

  .app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .app-header {

    .navbar {
      padding: 0 15px;
      min-height: 56px;

      .navbar-brand {
        width: calc($sidebar-width - 30px);

        @include media-breakpoint-down(md) {
          // This is to keep the title and the menu buttons in order. Normally the brand is position: absolute which distorts the order
          position: relative;
          top: auto;
          left: auto;
          margin: 0px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .navbar-toggler {
        color: #fff;
        height: 35px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .navbar-toggler {
      .navbar-toggler-icon, &:hover .navbar-toggler-icon {
        background-image: $navbar-dark-toggler-icon-bg;
      }
    }

    .navbar-nav {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .ivis-navbar-nav-right {
      @include media-breakpoint-up(lg) {
        flex-grow: 1;
        justify-content: flex-end;
      }

      .nav-item {
        .nav-link {
          padding-left: 0px;
          padding-right: 0px;
        }

        @include media-breakpoint-up(lg) {
          margin-left: 15px;
        }

        @include media-breakpoint-down(md) {
          margin-right: 15px;

          .dropdown-menu-right {
            right: auto;
            left: 0;
          }
        }
      }
    }

    .ivis-navbar-nav-left {
      @include media-breakpoint-up(lg) {
        margin-left: 15px;
      }

      .nav-item {
        margin-right: 15px;
      }
    }

    .nav-item {
      min-width: 0px;
    }
  }

  .app-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    .sidebar {
      flex: 0 1 $sidebar-width;
      padding: 0 1rem;
      flex-direction: column;

      .sidebar-header {
        text-align: center;
        padding: 0.5rem 0;
      }

      nav {
        align-self: start;
      }
    }

    .main {
      flex-grow: 1;
    }
  }

  @include media-breakpoint-down(lg) {
    .app-body {
      flex-direction: column;
    }
  }

  .ivis-panel-wrapper {
    padding: 15px;

    .ivis-panel {
      margin: 0px;
    }
  }

  @include media-breakpoint-down(md) {
    .ivis-panel-wrapper {
      padding: 4px 0px 0px 0px;
    }
  }

  .panel-in-fullscreen {
    .ivis-panel-wrapper {
      padding: 0px;
    }
  }

  .navbar-dark {
    .navbar-nav {
      .active > .nav-link:hover {
        color: $navbar-dark-active-color;
      }
    }
  }
}

@media print {
  @page {
    size: A3; // During print, we scale down from A3 to A4. This yields good font and chart size on the paper.
    margin: 15px;
  }

  .page-block {
    break-inside: avoid;
  }

  .page-break-after {
    break-after: page;
  }
}

// This is hack for not working validation of input groups in BS4 (https://github.com/twbs/bootstrap/issues/23454)
.invalid-feedback {
  display: block;
}


