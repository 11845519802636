$table-cell-padding: .5rem;
$input-border-color: #adb5bd;

$breadcrumb-bg: #f6f7f8;

$navbar-dark-color: rgba(#fff, .75) !default;
$navbar-dark-hover-color: #fff !default;

// React-day-picker - used to match colors
$rdp-color-primary: #3f5efb;
$rdp-color-secondary: #fc466b;

